import React, { useEffect, useRef } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Section3() {
  const imgRef = useRef(null);
  const textReff = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
      textReff.current,
      { scale: 2, opacity: 0 }, // Start with larger scale and hidden
      {
        scale: 1, // End with normal scale
        opacity: 1, // Fully visible
        scrollTrigger: {
          trigger: textReff.current,
          start: "top 70%", // Trigger animation when element is 80% from the top
          end: "top 20%", // End animation when element is 30% from the top
          scrub: true,
        },
        ease: "power3.out", // Animation easing
        duration: 1.5, // Duration of the animation
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "-100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 100%", // Trigger animation when element is 70% from the top
          end: "top 60%", // End when the top of the button is 50% from the top of the viewport
          scrub: 2, // Smooth animation synced with scroll
        },
      }
    );
  }, []);
  return (
    <section className="section-3">
      <div
        ref={imgRef}
        className="section-padding"
        style={{ overflow: "hidden" }}
      >
        <div className="row mx-0">
          <div className="col-lg-3 pe-lg-5 ps-0">
            {/* <img
              src="/gold-coin.png"
              className="img-fluid img1"
              alt="Bracelet"
            /> */}
          </div>
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <h3 className="text-center" ref={textReff}>
              Invest In Gold <br /> Physically
            </h3>
          </div>
          <div className="col-lg-3 ps-lg-5">
            {/* <img src="/gold-inhand.png" className="img-fluid" alt="Bracelet" /> */}
          </div>
        </div>
        <div className="row mt-lg-3 mx-0">
          <div className="col-lg-4 d-flex justify-content-end">
            {" "}
            {/* <img
              src="/gold-bar.png"
              className="img-fluid align-self-center"
              alt="Bracelet"
            /> */}
          </div>
          <div
            className="col-lg-4 d-flex justify-content-center"
            onClick={() =>
              window.open("https://digigold.luckystargold.com/", "_blank")
            }
          >
            {" "}
            <img
              src="/gold-stone.png"
              className="img-fluid align-self-center"
              alt="Bracelet"
            />
          </div>
          <div className="col-lg-4 d-flex justify-content-end pe-0">
            {" "}
            {/* <img
              src="/gold-bars.png"
              className="img-fluid align-self-end"
              alt="Bracelet"
            /> */}
          </div>
        </div>
        <div className="px-xlg-5">
          <p className=" section-3-container mx-auto px-lg-5 fs-5 px-xlg-5">
            With our platform, you can purchase digital gold that is backed by
            real, physical gold. When you buy digital gold through our website,
            you're not just making a virtual transaction; you're actually
            acquiring ownership of physical gold that we securely store for you.
            This offers the convenience of digital transactions with the
            security and assurance of owning tangible gold. Whether you want to
            trade or eventually convert it into physical gold, our service gives
            you the flexibility to manage your gold investments with ease.
          </p>
        </div>
        <div className="d-flex justify-content-center mt-lg-5">
          <button
            className="home-btn rounded-pill align-self-start mt-3 mt-lg-5"
            ref={buttonRef}
            onClick={() =>
              window.open("https://digigold.luckystargold.com/", "_blank")
            }
          >
            Investment In Gold
          </button>
        </div>
      </div>
    </section>
  );
}

export default Section3;
