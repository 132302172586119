import React, { useEffect, useRef } from "react";
import "./style.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Section4() {
  const imgRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
      textRef.current,
      { scale: 1.5, opacity: 0 }, // Start with larger scale and hidden
      {
        scale: 1, // End with normal scale
        opacity: 1, // Fully visible
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 70%", // Trigger animation when element is 80% from the top
          end: "top 30%", // End animation when element is 30% from the top
          scrub: true, // Sync animation with scroll position
        },
        ease: "power3.out", // Animation easing
        duration: 1.5, // Duration of the animation
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "-100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 100%", // Trigger animation when element is 70% from the top
          end: "top 70%", // End when the top of the button is 50% from the top of the viewport
          scrub: 1, // Smooth animation synced with scroll
        },
      }
    );
  }, []);
  return (
    <section className="section-4">
      <div
        ref={imgRef}
        className="container section-padding"
        style={{ overflow: "hidden" }}
      >
        <h4 className="text-center" ref={textRef}>
          Purchase Our Collection
        </h4>
        <div className="row mt-3 mt-lg-5 pt-lg-4">
          <div
            className="col-lg-3 px-0 d-flex flex-column align-items-center  gap-3  text-center  pt-3 "
            onClick={() =>
              window.open(
                "http://store.luckystargold.com/product/details?pid=8dcc2d12-034b-4b62-916f-b2223ee35cf8&name=RWNCL103",
                "_blank"
              )
            }
          >
            <img src="/neclace-image.png" className="w-90" alt="Bracelet" />
            <div className="d-flex flex-column gap-3 gap-lg-5 box py-4 px-4">
              <span>
                RWNCL103 <br />
                NECLACE
              </span>
              <span>
                Wide range of low weight neclace with zero making offer
                available. Grab yours.
              </span>
              <span className="price">2572.17 AED</span>
            </div>
          </div>
          <div
            className="col-lg-3 px-0 d-flex flex-column align-items-center  gap-3 text-center  pt-3 "
            onClick={() =>
              window.open(
                "http://store.luckystargold.com/product/details?pid=9e384bfa-f1a9-4420-91a7-3e686fd1750c&name=22kt%20Gold%20Earrings%20adorned%20with%20Exquisite%20Round%20Gemstones?",
                "_blank"
              )
            }
          >
            <img src="/earring-image.png" className="w-90" alt="Bracelet" />
            <div className="d-flex flex-column gap-3 gap-lg-5 box py-4 px-4">
              <span>
                EARRINGS
                <br />
                22kt Gold Earrings adorned with Exquisite Round Gemstones
              </span>
              <span>
                22kt Gold Earrings adorned with Exquisite Round Gemstones
              </span>
              <span className="price">399.56 AED</span>
            </div>
          </div>
          <div
            className="col-lg-3 px-0 d-flex flex-column align-items-center  gap-3 text-center  pt-3 "
            onClick={() =>
              window.open(
                "http://store.luckystargold.com/product/details?pid=960939af-7a47-444d-a36c-e8580e9a624b&name=RWBNG103",
                "_blank"
              )
            }
          >
            <img src="/gold-bangles.png" className="w-90" alt="Bracelet" />
            <div className="d-flex flex-column gap-3 gap-lg-5 box py-4 px-4">
              <span>
                RWBNG103 <br />
                Gold Bangles
              </span>
              <span>ZERO MAKING CHARGES WITH STUNNING DESIGN</span>
              <span className="price">3428.58 AED</span>
            </div>
          </div>
          <div className="col-lg-3 px-0 d-flex flex-column align-items-center  gap-3 text-center  pt-3 ">
            <img src="/ring-gold.png" className="w-90" alt="Bracelet" />
            <div className="d-flex flex-column gap-3 gap-lg-5 box py-4 px-4">
              <span>
                KIDS RING
                <br />
                from "PAMP"
              </span>
              <span>KIDS RING</span>
              <span className="price">324.84 AED</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mt-lg-5">
          <a href="https://store.luckystargold.com/" target="_blank">
            <button
              className="home-btn rounded-pill align-self-start "
              ref={buttonRef}
            >
              Visit Store
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Section4;
