import React, { useEffect, useRef } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Section5() {
  const sectionRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      sectionRef?.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: sectionRef?.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
        buttonRef.current,
        { x: "100%", opacity: 0 }, // Start 30% to the left with opacity 0
        {
          x: "0%", // End at the original position
          opacity: 1, // Fully visible
          duration: 1.5, // Duration of the animation
          ease: "power3.out", // Smooth easing
          scrollTrigger: {
            trigger: buttonRef.current,
            start: "top 100%", // Trigger animation when element is 70% from the top
            end: "top 70%", // End when the top of the button is 50% from the top of the viewport
            scrub: 2, // Smooth animation synced with scroll
          },
        }
      );
  }, []);
  return (
    <section className="section-5 text-light p-3 py-lg-5">
      <div className="container" ref={sectionRef}>
        <div className="row">
          <div className="col-lg-6">
            <img src="/gold-bars.png" alt="" className="w-100" />
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center">
            <div className="ps-lg-5">
              <h4>Golden Bids: Exclusive Auctions</h4>
              <p>
                Bid on unique gold items and secure exclusive pieces at
                unbeatable prices. Don't miss your chance to own something truly
                special!
              </p>
              <a href="https://auction.luckystargold.com/" target="_blank">
                <button className="home-btn rounded-pill" ref={buttonRef}>Visit Site</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section5;
