import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaCcVisa } from "react-icons/fa";
import { FaCcMastercard } from "react-icons/fa";
import { FaCcPaypal } from "react-icons/fa";
import { FaCcApplePay } from "react-icons/fa";
import "./style.scss";

function Footer() {
  return (
    <footer className="py-3 py-lg-5 bg-dark">
      <div className="container">
        <div className="row gy-3">
          <div className="col-lg-8">
            <div className="row gy-3">
              <div className="col-lg-4">
                <img src="/applogo.png" alt="" className="img-fluid" />
                <div className="d-flex">{/* <FaLinkedinIn /> */}</div>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold">Company</span>
                <br />
                <br />
                <ul className="nav d-flex flex-column gap-2">
                  {[
                    "About",
                    "Best Price",
                    "Service",
                    "Centers",
                    "Careers",
                    "Solution Bar",
                    "Brand Promise",
                    "Terms and Conditions",
                  ].map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                  <li>
                    <a
                      href="https://auction.luckystargold.com/"
                      className="text-decoration-none text-light"
                    >
                      LSG Auction
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold">Assistance</span>
                <br />
                <br />
                <ul className="nav d-flex flex-column gap-2">
                  {[
                    "Check Warranty Status",
                    "Store Locator",
                    "Easy Payment Plan",
                    "Disclaimer Policy",
                    "Return and Exchange Policy",
                    "Bulk Order Enquiries",
                    "Report Fraud",
                  ].map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="row">
              <div className="col-lg-6">
                <span className="fw-bold">Publications</span>
                <br />
                <br />
                <ul className="nav d-flex flex-column gap-2">
                  {["Newsletter", "Tell Us More", "Share"].map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6">
                <span className="fw-bold">Publications</span>
                <br />
                <br />
                <ul className="nav d-flex flex-column gap-2">
                  {["LSG Digital Gold", "LSG 3D Studio"].map((item, key) => (
                    <li key={key}>{item}</li>
                  ))}
                </ul>
                <br />
                <br />
                <br />
                <span className="fw-bold">Payment Methods</span>
                <div className="d-flex mt-lg-3 gap-2">
                  <FaCcVisa size={22} />
                  <FaCcMastercard size={22} />
                  <FaCcPaypal size={22} />
                  <FaCcApplePay size={22} />
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
        <hr className="text-light" />
        <div className="d-flex justify-content-between mt-lg-4">
          <p>Copyright © 2024 Lucky Star Gold LLC | All rights Reserved.</p>
          <p>Terms and conditions Privacy policy and cookies</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
