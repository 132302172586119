import React, { useEffect, useRef } from "react";
import "./App.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from "./components/header";
import VideoSection from "./components/video-section";
import Section1 from "./components/section1";
import Section2 from "./components/section2";
import Section3 from "./components/section3";
import Section4 from "./components/section4";
import Footer from "./components/footer";
import CustomCursor from "./components/cursor";
import Section5 from "./components/section5";

function App() {
  return (
    <>
      <CustomCursor />
      <Header />
      <VideoSection />
      <main className="bg-dark">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </main>
      <Footer />
    </>
  );
}

export default App;
