import React, { useEffect, useRef } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function Section1() {
  const imgRef = useRef(null);
  const elementRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
      elementRef.current,
      { scale: 2, opacity: 0 }, // Start with a large scale and hidden
      {
        scale: 1, // End with normal scale
        opacity: 1, // Fully visible
        scrollTrigger: {
          trigger: elementRef.current,
          start: "top 70%", // Trigger animation when element is 70% from the top
          end: "top 40%", // End animation when element is 50% from the top
          scrub: 1, // Smooth syncing with scroll position
        },
        ease: "power3.out", // Smooth easing
        duration: 1.5, // Slightly longer duration for smoother effect
      }
    );
    gsap.fromTo(
      buttonRef.current,
      { x: "-100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 100%", // Trigger animation when element is 70% from the top
          end: "top 70%", // End when the top of the button is 50% from the top of the viewport
          scrub: 2, // Smooth animation synced with scroll
        },
      }
    );
  }, []);
  return (
    <section className="section-1">
      <div
        className="container section-padding"
        ref={imgRef}
        style={{ overflow: "hidden" }}
      >
        <h3 className="text-center section-1-title">
          Customize Our <br className="d-none d-lg-block" /> Collection
        </h3>
        <div
          className="row mt-lg-5 pt-lg-5  mx-auto px-lg-5 w-90 gy-3"
          ref={elementRef}
        >
          <div
            className="col-lg-3 d-flex justify-content-center box-main align-items-center  p-5 p-lg-4"
            onClick={() =>
              window.open(
                "https://customize.luckystargold.com/",
                "_blank"
              )
            }
          >
            <img
              src="/ring22-gold.svg"
              color="red"
              className="img-fluid w-75 align-self-center filter-green hover"
              alt="Image1"
            />
            <img
              src="/img11.png"
              className="img-fluid w-75 align-self-center  filter-green active"
              alt="Image1"
            />
          </div>
          <div
            className="col-lg-3 d-flex justify-content-center box-main align-items-center p-5 p-lg-4"
            onClick={() =>
              window.open(
                "https://customize.luckystargold.com/",
                "_blank"
              )
            }
          >
            <img
              src="/ring.svg"
              className="img-fluid w-75 align-self-center filter-green hover"
              alt="Image1"
            />
            <img
              src="/ring.png"
              className="img-fluid w-75 align-self-center filter-green active"
              alt="Image1"
            />
          </div>
          <div
            className="col-lg-3 d-flex justify-content-center box-main align-items-center p-5 p-lg-4"
            onClick={() =>
              window.open(
                "https://customize.luckystargold.com/",
                "_blank"
              )
            }
          >
            <img
              src="/neclace-gold.svg"
              className="img-fluid w-75 align-self-center filter-green hover"
              alt="Image1"
            />
            <img
              src="/img33.png"
              className="img-fluid w-75 align-self-center filter-green active"
              alt="Image1"
            />
          </div>
          <div
            className="col-lg-3 d-flex justify-content-center box-main align-items-center p-5 p-lg-4"
            onClick={() =>
              window.open(
                "https://customize.luckystargold.com/",
                "_blank"
              )
            }
          >
            <img
              src="/bangle-gold.svg"
              className="img-fluid w-75 align-self-center filter-green hover"
              alt="Image1"
            />
            <img
              src="/img44.png"
              className="img-fluid w-75 align-self-center filter-green active"
              alt="Image1"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mt-lg-5">
          <a href="https://customize.luckystargold.com/" target="_blank">
            <button
              className="home-btn rounded-pill align-self-start "
              ref={buttonRef}
            >
              Customize
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Section1;
