import React, { useEffect, useRef } from "react";
import img1 from "../../assets/images/box.png";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ModelViewer from "../3d/view";
import { BraceletModel } from "../3d/Bracelet";
import { Canvas } from "@react-three/fiber";
gsap.registerPlugin(ScrollTrigger);
function Section2() {
  const imgRef = useRef(null);
  const buttonRef = useRef(null);
  const textRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 80%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 100%", // Trigger animation when element is 70% from the top
          end: "top 70%", // End when the top of the button is 50% from the top of the viewport
          scrub: 2, // Smooth animation synced with scroll
        },
      }
    );

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: textRef.current,
        start: "top 90%", // Start when the top of the section is 80% from the top of the viewport
        end: "top 20%", // End when the top of the section is 20% from the top of the viewport
        scrub: 1,
      },
    });

    tl.fromTo(
      titleRef.current,
      { scale: 2, opacity: 0 },
      { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" }
    )
      .fromTo(
        paragraphRef.current,
        { scale: 2, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" },
        "-=0.6" // Overlap the animation with the previous by 0.6 seconds
      )
      .fromTo(
        imageRef.current,
        { scale: 2, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" },
        "-=0.6" // Overlap the animation with the previous by 0.6 seconds
      );
  }, []);
  return (
    <section className="section-2">
      <div
        ref={imgRef}
        className="container section-padding"
        style={{ overflow: "hidden" }}
      >
        <div className="row">
          <div
            className="col-lg-5 d-flex flex-column justify-content-center"
            onClick={() =>
              window.open(
                "https://www.uploadyours.luckystargold.com/",
                "_blank"
              )
            }
          >
            <div ref={textRef}>
              <h3 className="" ref={titleRef}>
                Make Your <br /> Own Jwellery
              </h3>
              <p className="mt-3 mt-lg-4" ref={paragraphRef}>
                If you've ever seen a piece of jewelry in an old photo or online
                that you wished you could own, now you can make it a reality.
                Simply send us the image, and we'll craft the jewelry exactly to
                your liking. Whether it's a vintage design, a modern piece, or
                something entirely unique, we'll bring your vision to life,
                ensuring it fits your style and needs perfectly. With our custom
                service, you can wear the jewelry you've always dreamed of,
                tailored just for you.
              </p>
              <img
                ref={imageRef}
                src="/gold-oldpaper.png"
                className="img-fluid w-100"
                alt="Bracelet"
              />
              <div className="d-flex justify-content-start mt-3 mt-lg-5">
                <button
                  className="home-btn rounded-pill align-self-start "
                  ref={buttonRef}
                  onClick={() =>
                    window.open(
                      "https://www.uploadyours.luckystargold.com/",
                      "_blank"
                    )
                  }
                >
                  Send Design
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <ModelViewer modelUrl="/bracelet.glb" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;
