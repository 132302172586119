import React, { useEffect, useRef } from "react";
import "./style.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function VideoSection() {
  const newRef = useRef(null);
  const imgRef = useRef(null);
  const titleRef = useRef(null);
  const subtextRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: newRef.current,
        start: "top top+=50", // Start animation after scrolling 100 pixels
        end: "+=200",
        scrub: 1,
      },
    });

    tl.fromTo(
      imgRef.current,
      { y: 0, opacity: 1 }, // Initially visible
      { y: -50, opacity: 1, duration: 1, ease: "power3.out" } // Slight upward movement
    )
      .fromTo(
        titleRef.current,
        { y: 0, opacity: 1 },
        { y: -50, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      )
      .fromTo(
        subtextRef.current,
        { y: 0, opacity: 1 },
        { y: -40, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      )
      .fromTo(
        buttonRef.current,
        { y: 0, opacity: 1 },
        { y: -60, opacity: 1, duration: 1, ease: "power3.out" },
        "-=0.8"
      );
  }, []);
  return (
    <div className="Home-Parallax1">
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="full-width-video align-bottom"
        style={{ height: "100vh", objectFit: "cover" }}
      >
        <source type="video/mp4" src="/video.mp4" />
      </video>

      <div
        className="container"
        onClick={() => window.open("http://store.luckystargold.com/", "_blank")}
      >
        <div className="Home-videoHover d-flex flex-column gap-0" ref={newRef}>
          <img
            className="Home-videoHover-img"
            src="/gold.png"
            alt="Overlay"
            ref={imgRef}
          />
          <h3 className="banner-text" ref={titleRef}>
            Making Charge
          </h3>
          <div className="banner-subtext" ref={subtextRef}>
            Craft Gold Jewelery With Zero <br />
            Making Charges!
          </div>
          <a href="http://store.luckystargold.com/" target="_blank">
            <button
              className="home-btn rounded-pill align-self-start mt-lg-3"
              ref={buttonRef}
            >
              Visit Store
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoSection;
