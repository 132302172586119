import React, { useEffect, useState } from "react";
import "./style.scss";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHoveringNav, setIsHoveringNav] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    const handleMouseOver = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        (event.target.tagName === "NAV" || event.target.closest(".navbar"))
      ) {
        setIsHoveringNav(true);
      }
    };

    const handleMouseOut = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        (event.target.tagName === "NAV" || event.target.closest(".navbar"))
      ) {
        setIsHoveringNav(false);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseover", handleMouseOver);
    window.addEventListener("mouseout", handleMouseOut);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseover", handleMouseOver);
      window.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  // Conditionally render the custom cursor only when not hovering over a nav element with the class 'navbar'
  return !isHoveringNav ? (
    <div
      className="custom-cursor"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    />
  ) : null;
};

export default CustomCursor;
