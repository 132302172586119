import React, { useEffect, useState } from "react";
import "./style.scss";

function Header() {
  const [isScroled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);
  return (
    <nav
      className={`navbar navbar-expand-lg  pt-lg-4 fixed-top px-lg-5  ${
        isScroled ? "d-none" : ""
      }`}
    >
      <div className="container-fluid px-lg-5">
        <a className="navbar-brand" href="#">
          <img src="/applogo.png" className="navbar-logo" alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse bg-lg-dark" id="navbarNav">
          <ul className="navbar-nav flex-grow-1">
            <li className="nav-item ms-lg-auto px-3">
              <a
                className="nav-link  head-text-color rounded-pill"
                aria-current="page"
                href="https://digigold.luckystargold.com/about"
                target="_blank"
              >
                About
              </a>
            </li>
            <li className="nav-item px-3">
              <a
                className="nav-link head-text-color rounded-pill"
                href="https://digigold.luckystargold.com/contact"
                target="_blank"
              >
                Contact Us
              </a>
            </li>
            <li className="nav-item px-3">
              <a
                className="nav-link head-text-color rounded-pill"
                href="http://store.luckystargold.com/"
                target="_blank"
              >
                Store
              </a>
            </li>
            <li className="nav-item px-3">
              <a
                className="nav-link head-text-color rounded-pill"
                href="https://customize.luckystargold.com/"
                target="_blank"
              >
                Customization
              </a>
            </li>
            <li className="nav-item px-3">
              <a
                className="nav-link head-text-color rounded-pill"
                href="https://digigold.luckystargold.com/"
                target="_blank"
              >
                Digital Gold
              </a>
            </li>
            <li className="nav-item px-3">
              <a
                className="nav-link head-text-color rounded-pill"
                href="https://auction.luckystargold.com/"
                target="_blank"
              >
                LSG Auction
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
