import React from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";

const Model = ({ url }: any) => {
  const { scene }: any = useGLTF(url);
  scene.scale.set(3, 3, 3); // Adjust the values to your needs
  return <primitive object={scene} />;
};

const ModelViewer = ({ modelUrl }: any) => {
  return (
    <Canvas>
      {/* Increase intensity for better lighting */}
      <ambientLight intensity={1.0} /> {/* Brighter ambient light */}
      <directionalLight position={[20, 50, 100]} intensity={2.5} />{" "}
      {/* Brighter directional light */}
      <Environment preset="forest" />
      <spotLight
        position={[10, 10, 10]}
        angle={0.3}
        penumbra={1}
        intensity={10}
        castShadow
      />
      <OrbitControls minZoom={1.1} maxZoom={1.4} autoRotate />
      <Model url={modelUrl} />
    </Canvas>
  );
};

export default ModelViewer;
